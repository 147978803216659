
import { Vue, Component, Watch } from 'vue-property-decorator';
import { JoinModule } from '@/store/join';
import { defaultJoinForm } from '@/api/user';
import { UserModule } from '@/store/modules/user';
import { getJwtInfo } from '@/utils/cookies';

@Component({
  name: 'joinStep',
  components: {
  },
})

export default class extends Vue {
  @Watch('form.terms1')
  private handleChangeTerms1() {
    this.handleCheckAllTerms();
  }

  @Watch('form.terms2')
  private handleChangeTerms2() {
    this.handleCheckAllTerms();
  }

  @Watch('form.terms3')
  private handleChangeTerms3() {
    this.handleCheckAllTerms();
  }

  @Watch('form.terms4')
  private handleChangeTerms4() {
    this.handleCheckAllTerms();
  }

  @Watch('form.marketingStatus')
  private handleChangeMarketingStatus() {
    this.handleCheckAllTerms();
  }

  mounted() {
    this.checkSocialUser();
  }

  get form() {
    return JoinModule.form;
  }

  private termsShow: any = {
    show1: false,
    show2: false,
    show3: false,
    show4: false,
    show5: false,
  }

  private handleShowTerms(item: any) {
    this.termsShow[item] = !this.termsShow[item];
  }

  private allTerms = false;

  private handleAllTermCheck() {
    this.allTerms = !this.allTerms;
    this.form.terms1 = this.allTerms;
    this.form.terms2 = this.allTerms;
    this.form.terms3 = this.allTerms;
    this.form.terms4 = this.allTerms;
    this.form.marketingStatus = this.allTerms;
  }

  private terms1 = '';

  private terms2 = '';

  private terms3 = '';

  private terms4 = '';

  private terms5 = '';

  private handleAgree() {
    if (this.form.terms1 && this.form.terms2 && this.form.terms3 && this.form.terms4) {
      this.form.step = 2;
      this.$router.push({ name: 'Signup' }).catch((error) => console.log(error));
    } else {
      this.$message.warning('필수 약관동의를 해주세요.');
    }
  }

  private handleCheckAllTerms() {
    if (this.form.terms1 && this.form.terms2 && this.form.terms3 && this.form.terms4 && this.form.macketingStatus) {
      this.allTerms = true;
    } else {
      this.allTerms = false;
    }
  }

  private checkSocialUser() {
    const token = UserModule.token;
    if (token) {
      const status = getJwtInfo('status');
      if (status) {
        this.$router.push({ name: 'Home' });
      } else {
        JoinModule.setForm(defaultJoinForm());
      }
    } else {
      this.$router.push({ name: 'Login' });
    }
  }
}
